import { alpha } from "@mui/material";

export const Colors: any = {
  primary: "#AFBC9D",
  black: "#030303",
  darkGrey: "#262923",
  accent: "#7AC03F",
  lightGrey: `${alpha("#030303", 0.1)}`,
  white: "#FFFFFF",
  primaryText: "#030303",
  secondaryText: `${alpha("#030303", 0.5)}`,
  background: "#FAFAFA",
  warning: "#D93A3A",
  swanKhaki: "#5A5F53",
  swanMarine: "#546e7a",
  green: "#44B24C",
  success: "#44B24C",
  error: "#D93A3A",
  yellow: "#F6A826",
  skyblue: "#87CEEB",
  red: "#FF0000",
  score: {
    green: "#62BA09",
    yellow: "#FCA903",
    red: "#D62B36",
  },
};
