import React, { useEffect, useRef, useState } from "react";
import {
  SwanStatusInfo,
  SwanTransactionSide,
  TransactionFailedReasonEnum,
  transactionServices,
  transactionsQuery,
  TransactionStatus,
} from "../../../../store/transaction";
import { alpha, Button, Card, Chip, Dialog, Grid, IconButton, Stack, Switch, Typography } from "@mui/material";
import { Colors } from "../../../../constants/colors.constants";
import { Close, Edit, Delete } from "@mui/icons-material";
import ReadonlyField from "../../../../components/readonlyField.component";
import { categoriesServices, Category, CategoryType } from "../../../../store/serviceCategories";
import dayjs from "dayjs";
import { useObservable } from "@ngneat/react-rxjs";
import { useSnackbar } from "notistack";
import EditTransaction from "./EditTransaction.modal";
import { globalSettingsServices } from "../../../../store/globalSettings";
import { UserRole } from "../../../../store/users";
import { finalize } from "rxjs";
import { sessionQuery } from "../../../../store/session";
import { t } from "i18next";
import DeleteTransactionModal from "./DeleteTransaction.modal";
import { useResponsive } from "src/utils/useResponsive";
import ConfirmDialog from "src/components/Confirm.component";
import { midExceptionsServices } from "src/store/mid";
import { SelectFieldComponent } from "src/components/form/select-field.component";
import { categoriesQuery } from "src/store/serviceCategories/categories.query";

interface TransactionDetailProps {
  transactionId?: string;
  openEdit?: boolean;
  previousASP?: () => Object;
  handleClose: () => void;
}

interface Invoice {
  key: string;
  url: string;
}

const TransactionDetail = (props: TransactionDetailProps) => {
  const { transactionId, handleClose, previousASP, openEdit } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [invoice, setInvoice] = useState<Invoice>();
  const [{ transaction }] = useObservable(transactionsQuery.trasaction$);
  const [{ company }] = useObservable(sessionQuery.company$);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteTransactionModal, setDeleteTransactionModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = sessionQuery?.user;
  const isMobile: boolean = useResponsive();
  const [exceptionMIDModal, setExceptionMIDModal] = useState(false);
  const invoiceRef = useRef<HTMLDivElement>(null);
  const [selectedCategory, setSelectedCategory] = useState<Category>();

  const [{ categories }] = useObservable(categoriesQuery.categories$);

  useEffect(() => {
    setTimeout(() => {
      if (isMobile && invoiceRef.current) {
        invoiceRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  }, [isMobile, transaction]);

  const dataToComplete = {
    justificatif: company?.isInvoiceRequired && !transaction?.invoice,
    motif: company?.isReasonRequired && !transaction?.reason,
    distanceKmEstimate: transaction?.serviceCategory?.type === CategoryType.MSP && !transaction?.distanceKmEstimate,
    vehicleKilometersAtTransaction:
      transaction?.serviceCategory?.type === CategoryType.ASP &&
      transaction?.serviceCategory?.isSubjectToKilometers &&
      !transaction?.vehicleKilometersAtTransaction,
  };

  const updateTransaction = (isUpdatable: boolean) => {
    setLoading(true);
    transactionServices
      .updateTransactionIsUpdatable({
        ...transaction,
        isUpdatable: isUpdatable,
      })
      .pipe(
        finalize(() => {
          setLoading(false);
          enqueueSnackbar(
            `${t("global.fieldUpdated", {
              field: `${t("payments.export.form.paymentCBUpdatable")}`,
            })}`,
            { variant: "success" }
          );
        })
      )
      .subscribe({
        error: (err) => {
          enqueueSnackbar(err.text, err.options);
        },
      });
  };

  useEffect(() => {
    getTransaction();
    getServiceCategory();
  }, [transactionId]);

  useEffect(() => {
    if (openEdit) setTimeout(() => setEditOpen(true), 200);
  }, [openEdit]);

  useEffect(() => {
    if (transaction?.invoice) {
      globalSettingsServices.getFile(transaction.invoice).subscribe({
        next: (value) => {
          setInvoice({ key: value.key, url: value.url });
        },
      });
    }
  }, [transaction?.invoice]);

  // Function to close the modal and clean URL
  const handleCloseAndCleanUrl = () => {
    setEditOpen(false);
    localStorage.setItem("editModalOpen", JSON.stringify(false));
    /* const queryParams = new URLSearchParams(location.search);
    queryParams.delete("payment_id");
    navigate({ search: queryParams.toString() }, { replace: true }); */
    getTransaction();
    /* handleClose(); */
  };

  // Function to close the dialog
  const handleCloseDialog = () => {
    handleClose();
  };

  const getTransaction = () => {
    if (transactionId) {
      transactionServices.getTransactionById(transactionId).subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }
  };

  const getServiceCategory = () => {
    categoriesServices.getAllCategories().subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  if (!transaction) return <></>;

  const isPending = transaction?.swanStatusInfo === SwanStatusInfo.PENDING;
  const status = isPending ? TransactionStatus.PENDING : transaction.status;
  const statusLabel = TransactionStatus.label(status);
  const statusColor = TransactionStatus.color(status);
  const backgroundColor = alpha(statusColor, 0.2);

  const isPdf = (invoice: Invoice) => {
    return invoice.key.toLowerCase().endsWith(".pdf");
  };

  const handleCreateMIDException = () => {
    if (!selectedCategory) return;
    midExceptionsServices
      .requestMidException({
        mid: transaction.mid,
        name: transaction.merchantName + " " + transaction.merchantCity,
        serviceCategory: selectedCategory,
      })
      .subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    setExceptionMIDModal(false);
    enqueueSnackbar("Demande d'exception MID envoyée", { variant: "success" });
  };

  return (
    <>
      {editOpen && transaction && transaction?.status !== "FAILED" && (
        <EditTransaction transaction={transaction} handleClose={handleCloseAndCleanUrl} previousASP={previousASP} />
      )}
      {exceptionMIDModal && (
        <ConfirmDialog
          handleClose={() => setExceptionMIDModal(false)}
          handleValidate={handleCreateMIDException}
          confirm="Valider"
          cancel="Annuler"
          title="Demande exception MID"
          customDescription={
            <Stack direction="column" gap={2}>
              <Typography fontSize="18px" fontWeight={800}>
                Veuillez sélectionner la catégorie de la prestation
              </Typography>
              <SelectFieldComponent<Category>
                selectId="category"
                selectLabel="Catégorie"
                options={categories.map((it) => ({ label: it.name, value: it }))}
                onChange={setSelectedCategory}
                value={selectedCategory}
              />
            </Stack>
          }
        />
      )}
      <DeleteTransactionModal open={deleteTransactionModal} handleClose={handleClose} transaction={transaction} />
      <Dialog slotProps={{ backdrop: { style: { backgroundColor: "transparent" } } }} open={true} fullWidth fullScreen>
        <Stack
          direction="column"
          minHeight="100%"
          gap={4}
          p={4}
          sx={{ backgroundColor: Colors.background + " !important" }}
        >
          <Stack alignItems="start" gap={1}>
            <Stack direction="row" width="100%" justifyContent="space-between">
              <Typography fontSize="30px" fontWeight={800}>
                Détails du paiement
              </Typography>
              <IconButton onClick={handleCloseDialog}>
                <Close />
              </IconButton>
            </Stack>
            {transaction?.status && (
              <Chip
                label={statusLabel}
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  backgroundColor: backgroundColor,
                  color: statusColor,
                }}
              />
            )}
          </Stack>
          <Grid container direction="row" wrap="wrap" gap={2} height="100%">
            <Grid item xs>
              <Card sx={{ backgroundColor: "white", height: "100%" }}>
                <Stack p={3} direction="column" gap={3}>
                  <Typography fontSize={20} fontWeight={700}>
                    Informations Swan
                  </Typography>
                  <Stack direction="column" gap={2}>
                    <ReadonlyField
                      field="Titulaire de la carte"
                      value={transaction?.user?.lastName + " " + transaction?.user?.firstName}
                    />
                    <ReadonlyField field="Nom de la carte" value={transaction.card?.name} />
                    <ReadonlyField
                      field="Montant original"
                      value={`${transaction?.swanTransactionSide === SwanTransactionSide.CREDIT ? "+" : "-"}${
                        transaction?.amount
                      }€`}
                    />
                    <ReadonlyField
                      field="Montant en €"
                      value={`${transaction?.swanTransactionSide === SwanTransactionSide.CREDIT ? "+" : "-"}${
                        transaction?.amount
                      }€`}
                    />
                    {transaction?.dateBooked && (
                      <ReadonlyField
                        field="Date et heure du règlement"
                        value={
                          transaction.swanStatusInfo !== SwanStatusInfo.PENDING
                            ? Date.parse(transaction?.dateBooked ?? "")
                              ? dayjs(transaction?.dateBooked).format("dddd DD MMMM, HH:mm")
                              : ""
                            : ""
                        }
                      />
                    )}

                    {transaction?.date && (
                      <ReadonlyField
                        field="Date et heure du paiement"
                        value={
                          Date.parse(transaction?.date ?? "")
                            ? dayjs(transaction?.date).format("dddd DD MMMM, HH:mm")
                            : ""
                        }
                      />
                    )}
                    <ReadonlyField field="Nom du commerçant" value={transaction?.merchantName} />
                    <ReadonlyField field="Identifiant du commerçant (MID)" value={transaction?.mid} />
                    <ReadonlyField field="Ville" value={transaction?.merchantCity} />
                    <ReadonlyField field="Pays" value={transaction?.merchantCountry} />
                    <ReadonlyField field="Merchant Category Code (MCC)" value={transaction?.mcc} />
                    <ReadonlyField field="MCC description" value={transaction?.mccDescription} />
                  </Stack>
                </Stack>
              </Card>
            </Grid>
            <Grid item xs>
              <Card sx={{ backgroundColor: "white", height: "100%" }}>
                <Stack direction="column" gap={3} p={3}>
                  <Typography fontSize={20} fontWeight={700}>
                    Informations Impact
                  </Typography>
                  <Stack gap={2}>
                    <ReadonlyField field="Catégorie" value={transaction?.serviceCategory?.name} />
                    <ReadonlyField field="Pays" value={transaction?.merchantCountry} />
                    {transaction?.transactionFailedReason && (
                      <ReadonlyField
                        field="Raison du refus"
                        value={TransactionFailedReasonEnum.label(transaction.transactionFailedReason)}
                      />
                    )}
                    {transaction?.dateOfService && (
                      <ReadonlyField
                        field="Date de la prestation"
                        value={
                          dayjs(transaction?.dateOfService).format("DD/MM/YYYY") ||
                          dayjs(transaction?.date).format("DD/MM/YYYY")
                        }
                      />
                    )}
                    {(transaction?.serviceCategory?.type === CategoryType.ASP ||
                      transaction?.serviceCategory?.type === CategoryType.MSP) && (
                      <ReadonlyField
                        field="Kilométrage"
                        value={
                          transaction?.vehicleKilometersAtTransaction
                            ? `${transaction?.vehicleKilometersAtTransaction} km`
                            : ""
                        }
                        required={dataToComplete.vehicleKilometersAtTransaction}
                      />
                    )}
                    {transaction?.serviceCategory?.type === CategoryType.OSP && (
                      <ReadonlyField field="Quantité" value={transaction.quantity?.toString()} />
                    )}
                    {transaction?.serviceCategory?.type === CategoryType.MSP && (
                      <>
                        <ReadonlyField field="Aller-retour" value={transaction.isRoundTrip ? "Oui" : "Non"} />
                        <ReadonlyField field="Lieu de départ" value={transaction.fromLocation} />
                        <ReadonlyField field="Lieu d'arrivée" value={transaction.toLocation} />
                        <ReadonlyField
                          field="Km parcourus"
                          value={transaction.distanceKmEstimate ? `${transaction.distanceKmEstimate} km` : undefined}
                          required={dataToComplete.distanceKmEstimate}
                        />
                      </>
                    )}
                    <ReadonlyField field="Motif" value={transaction?.reason} required={dataToComplete.motif} />
                    <ReadonlyField field="TVA" value={transaction?.taxes.map((it) => it.percent + "%").toString()} />
                    <ReadonlyField
                      field="OCR"
                      color={transaction?.isAmountMatch ? "green" : "red"}
                      fieldColor={transaction?.isAmountMatch ? "green" : "red"}
                      value={transaction?.isAmountMatch ? "Oui" : "Non"}
                    />
                    <ReadonlyField
                      field="Statut"
                      color={transaction.status === TransactionStatus.OK ? "green" : "orange"}
                      fieldColor={transaction.status === TransactionStatus.OK ? "green" : "orange"}
                      value={TransactionStatus.label(transaction?.status ?? TransactionStatus.ACTION_REQUIRED)}
                    />
                    <ReadonlyField
                      field="Dernière modification"
                      value={transaction?.updatedAt ? dayjs(transaction?.updatedAt).format("DD/MM/YYYY") : ""}
                    />
                    {transaction?.updatedBy && (
                      <ReadonlyField
                        field="Par"
                        value={transaction?.updatedBy?.firstName + " " + transaction?.updatedBy?.lastName}
                      />
                    )}
                    <ReadonlyField
                      field="Modifiable"
                      valueElement={
                        <Switch
                          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            if (user?.role !== UserRole.EMPLOYEE) {
                              updateTransaction(evt.target.checked);
                            }
                          }}
                          disabled={loading}
                          checked={transaction?.isUpdatable === undefined || transaction?.isUpdatable === true}
                        />
                      }
                    />
                  </Stack>
                </Stack>
              </Card>
            </Grid>
            {transaction?.status !== TransactionStatus.FAILED && (
              <Grid item md={3} xs={12}>
                <Card sx={{ backgroundColor: "white", height: "100%" }}>
                  <Stack p={3} direction="column" gap={3}>
                    <Stack direction="row" spacing={2} alignItems={"center"}>
                      <Typography
                        fontSize={20}
                        fontWeight={700}
                        color={dataToComplete.justificatif ? "error" : "black"}
                      >
                        Justificatif
                      </Typography>
                      {dataToComplete.justificatif && (
                        <Typography fontSize={12} color="error">
                          A compléter
                        </Typography>
                      )}
                    </Stack>
                    {invoice &&
                      (isPdf(invoice) ? (
                        <>
                          <iframe src={invoice.url} title="Justificatif" width="100%" height="600px" />
                        </>
                      ) : (
                        <img src={invoice.url} alt="Justificatif" style={{ maxWidth: "100%" }} />
                      ))}
                    <div ref={invoiceRef} />
                  </Stack>
                </Card>
              </Grid>
            )}
          </Grid>
          <Stack
            style={{
              position: isMobile ? "fixed" : "static",
              bottom: isMobile ? "10px" : "auto",
              left: isMobile ? "50%" : "auto",
              transform: isMobile ? "translateX(-50%)" : "none",
              width: isMobile ? "100%" : "auto",
              display: "flex",
              gap: "10px",
              justifyContent: "center",
              padding: isMobile ? "0 16px" : "0",
            }}
          >
            {transaction?.status !== TransactionStatus.FAILED &&
              !(transaction?.isUpdatable === false && user?.role === UserRole.EMPLOYEE) && (
                <Stack alignItems="center">
                  <Button
                    disabled={!(transaction?.isUpdatable === undefined || transaction?.isUpdatable === true)}
                    color="secondary"
                    onClick={() => setEditOpen(true)}
                    startIcon={<Edit />}
                  >
                    Compléter le paiement
                  </Button>
                </Stack>
              )}
            {transaction?.status === TransactionStatus.FAILED &&
              (transaction?.transactionFailedReason === TransactionFailedReasonEnum.NO_RULE_FOR_CATEGORY ||
                transaction?.transactionFailedReason === TransactionFailedReasonEnum.MCC_UNREGISTERED) && (
                <Stack alignItems="center">
                  <Button color="secondary" onClick={() => setExceptionMIDModal(true)} startIcon={<Edit />}>
                    Ce paiement n’est pas correctement catégorisé ?
                  </Button>
                </Stack>
              )}
            {sessionQuery.role === UserRole.SUPER_ADMIN && user.accountHolderId && (
              <Stack alignItems="center">
                <Button color="error" onClick={() => setDeleteTransactionModal(true)} startIcon={<Delete />}>
                  Supprimer le paiement
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};

export default TransactionDetail;
