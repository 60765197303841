import { Button, Checkbox, Dialog, Grid, IconButton, Stack, Typography } from "@mui/material";
import { ReactElement, useState } from "react";
import { Colors } from "src/constants/colors.constants";
import Close from "src/assets/icons/icon_close_dark.svg";
import { useTranslate } from "src/utils/i18n.utils";
import { SelectFieldComponent } from "src/components/form/select-field.component";
import BasicTextfield from "src/components/inputs/BasicTextfield.component";
import APIAxios, { APIRoutes } from "src/api/axios.api";
import { enqueueSnackbar } from "notistack";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

export enum CardType {
  VIRTUAL = "VIRTUAL",
  PHYSICAL_VIRTUAL = "PHYSICAL_VIRTUAL",
}

export interface BankCardSettings {
  cardType: CardType;
  spendingLimit30d: number;
  allowWithdrawals: boolean;
  allowOnlinePayments: boolean;
  allowNonEuroTransactions: boolean;
  allowInternationalTransactions: boolean;
  printCardholderName: boolean;
  printCardName: boolean;
  printClientLogo: boolean;
  sharedCards: boolean;
}

export interface CreateCardMassModalProps {
  open: boolean;
  handleClose: () => void;
}

interface FormSteps {
  next?: () => void;
  setData?: (name: keyof BankCardSettings) => (value: any) => void;
  data: BankCardSettings;
  previous?: () => void;
  submit?: (file: File) => void;
}

const CardsFormat = (props: FormSteps) => {
  const { t } = useTranslate("createCardMass");
  const { t: t_global } = useTranslate("global");

  const { data, setData } = props;
  return (
    <Stack direction="column" gap={3} width="100%">
      <Typography textAlign="center" fontSize="20px" fontWeight={600}>
        {t("cardFormat")}
      </Typography>
      <Stack direction="column" gap={2}>
        <SelectFieldComponent
          required
          label={t("cardType")}
          options={Object.values(CardType).map((type) => ({ label: t(type), value: type }))}
          value={data.cardType}
          onChange={setData?.("cardType")}
        />
      </Stack>
      <Stack pt={4} direction="row" justifyContent="center">
        <Button variant="contained" color="primary" onClick={props.next}>
          {t_global("next")}
        </Button>
      </Stack>
    </Stack>
  );
};

interface CheckboxFieldComponentProps {
  label: string;
  value: boolean;
  onChange?: (value: boolean) => void;
}
export const CheckboxFieldComponent = (props: CheckboxFieldComponentProps) => {
  const { label, value, onChange } = props;

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" gap={3} width="100%">
      <Typography>{label}</Typography>
      <Checkbox checked={value} onChange={(e) => onChange?.(e.target.checked)} />
    </Stack>
  );
};

const CardsParams = (props: FormSteps) => {
  const { t } = useTranslate("createCardMass");
  const { t: t_global } = useTranslate("global");
  const { data, setData } = props;

  const checkboxFields = [
    { label: t("allowWithdrawals"), value: data.allowWithdrawals, onChange: setData?.("allowWithdrawals") },
    { label: t("allowOnlinePayments"), value: data.allowOnlinePayments, onChange: setData?.("allowOnlinePayments") },
    {
      label: t("allowNonEuroTransactions"),
      value: data.allowNonEuroTransactions,
      onChange: setData?.("allowNonEuroTransactions"),
    },
    {
      label: t("allowInternationalTransactions"),
      value: data.allowInternationalTransactions,
      onChange: setData?.("allowInternationalTransactions"),
    },
    { label: t("printCardholderName"), value: data.printCardholderName, onChange: setData?.("printCardholderName") },
    { label: t("printCardName"), value: data.printCardName, onChange: setData?.("printCardName") },
    // { label: t("printClientLogo"), value: data.printClientLogo, onChange: setData?.("printClientLogo") },
    { label: t("sharedCards"), value: data.sharedCards, onChange: setData?.("sharedCards") },
  ];
  return (
    <Stack direction="column" gap={3} width="100%">
      <Typography textAlign="center" fontSize="20px" fontWeight={600}>
        {t("cardParams")}
      </Typography>
      <Stack direction="column" gap={2} width="100%">
        <BasicTextfield
          number
          label={t("spendingLimit30d")}
          value={data.spendingLimit30d?.toString()}
          onChange={setData?.("spendingLimit30d")}
          placeholder={t("spendingLimit30d") ?? ""}
        />
        {checkboxFields.map((field, index) => (
          <CheckboxFieldComponent key={index} {...field} />
        ))}
      </Stack>
      <Stack pt={4} direction="row" justifyContent="center">
        <Button variant="contained" color="primary" onClick={props.next}>
          {t_global("next")}
        </Button>
      </Stack>
    </Stack>
  );
};

const CreateCards = (props: Partial<FormSteps>) => {
  const { t } = useTranslate("createCardMass");

  const handleDownloadCSV = () => {
    const link = document.createElement("a");
    link.href = "files/createCardMassExemple.csv";
    link.download = "creation_carte_masse_exemple.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleUploadCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      props.submit?.(file);
    }
  };

  return (
    <div>
      <Typography textAlign="center" fontSize="20px" fontWeight={600}>
        {t("createCards")}
      </Typography>
      <Typography textAlign="center" fontSize="16px" fontWeight={400}>
        {t("createCardsDescription")}
      </Typography>
      <Stack pt={4} direction="row" justifyContent="center" spacing={2}>
        <Button color="secondary" onClick={handleDownloadCSV}>
          {t("downloadCSV")}
        </Button>
      </Stack>
      <Stack pt={4} direction="row" justifyContent="center">
        <Button component="label" variant="contained" color="primary">
          {t("uploadCSVandSubmit")}
          <input type="file" hidden accept=".csv" onChange={handleUploadCSV} />
        </Button>
      </Stack>
    </div>
  );
};
enum Steps {
  FORMAT,
  PARAMS,
  CREATE,
}

const initialData = {
  cardType: CardType.VIRTUAL,
  spendingLimit30d: 10000,
  allowWithdrawals: false,
  allowOnlinePayments: false,
  allowNonEuroTransactions: false,
  allowInternationalTransactions: false,
  printCardholderName: false,
  printCardName: false,
  printClientLogo: false,
  sharedCards: true,
};

const CreateCardMassModal = (props: CreateCardMassModalProps) => {
  const { open, handleClose } = props;
  const { t } = useTranslate("createCardMass");
  const { t: t_global } = useTranslate("global");
  const [currentStep, setCurrentStep] = useState(Steps.FORMAT);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<BankCardSettings>(initialData);

  const updateData = (name: keyof BankCardSettings) => (value: any) =>
    setData((prevData) => ({ ...prevData, [name]: value }));

  const handleSubmit = async (file: File) => {
    setLoading(true);
    try {
      const res = await APIAxios({ ...APIRoutes.POSTCreateCardMass(data, file) });
      if (res.data) {
        return res.data;
      }
    } catch (err) {
      enqueueSnackbar(t_global("errors.common"), { variant: "error" });
    } finally {
      setCurrentStep(Steps.FORMAT);
      setData(initialData);
      handleClose();
      setLoading(false);
    }
  };

  const steps: Record<Steps, ReactElement> = {
    [Steps.FORMAT]: <CardsFormat next={() => setCurrentStep(Steps.PARAMS)} data={data} setData={updateData} />,
    [Steps.PARAMS]: <CardsParams next={() => setCurrentStep(Steps.CREATE)} data={data} setData={updateData} />,
    [Steps.CREATE]: <CreateCards submit={handleSubmit} />,
  };

  return (
    <Dialog maxWidth="md" open={open} style={{ borderRadius: "8px", zIndex: 2000 }}>
      {loading && (
        <Grid
          bgcolor="rgba(0, 0, 0, 0.5)"
          width={"100%"}
          height={"100%"}
          position="absolute"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={2}
          zIndex={200}
        >
          <Typography fontSize="18px" fontWeight={600} color="white">
            {t_global("loading")}
          </Typography>
          <HourglassEmptyIcon style={{ color: "white", animation: "rotationHourglass 2s infinite linear" }} />
        </Grid>
      )}
      <Stack direction="column" gap={2} p={4} sx={{ backgroundColor: Colors.background }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography fontSize="30px" fontWeight={800}>
            {t("title")}
          </Typography>
          <IconButton onClick={handleClose}>
            <img alt="" src={Close} />
          </IconButton>
        </Stack>
        <Stack alignItems={"center"} spacing={2} px={"50px"}>
          {steps[currentStep]}
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default CreateCardMassModal;
