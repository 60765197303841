import { catchError, from, map, Observable, switchMap, tap } from "rxjs";
import { deleteEntities, setEntities, updateEntities } from "@ngneat/elf-entities";
import { transactionStore } from "./transaction.store";
import {
  ExportTransaction,
  ExportTransactionFilters,
  formatTransactionFullToTransactionLight,
  TransactionClosePeriod,
  TransactionFilter,
  TransactionFull,
  TransactionLight,
  TransactionTotal,
} from "./transaction.models";
import APIAxios, { APIRoutes } from "../../api/axios.api";
import SnackError from "../../utils/errors.utils";
import { AxiosError, AxiosResponse } from "axios";
import { getTransactionDataSource, GETTransactionTotalDataSource } from "./transaction.requests";
// import { transactionsQuery } from "./transaction.query";
// import { searchTransactionEffects } from "./transactionn.effects";
import { UploadFileResponse } from "@store/files";
import { downloadCSVObservable } from "../../utils/Document";

export class TransactionServices {
  store = transactionStore;

  setFilters = (filters: Partial<TransactionFilter>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));

  setExportTransaction = (exportTransaction: ExportTransaction) =>
    this.store.update((state) => ({
      ...state,
      exportTransaction: exportTransaction,
    }));

  resetStore = () => this.store.reset();

  getTransactions = (filters: TransactionFilter): Observable<TransactionLight[]> => {
    return from(APIAxios({ ...APIRoutes.GETTransactions(filters) })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<TransactionLight[]>) => {
        return response.data;
      }),
      tap((transactions) => {
        this.store.update(setEntities(transactions), getTransactionDataSource.setSuccess());
      }),
      getTransactionDataSource.trackRequestStatus()
    );
  };

  getTransactionById = (id: string): Observable<TransactionFull> => {
    return from(APIAxios({ ...APIRoutes.GETTransactionById(id) })).pipe(
      catchError((err) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<TransactionFull>) => {
        return response.data;
      }),
      tap((transaction) => {
        this.store.update(
          (state) => ({
            ...state,
            transaction,
          }),
          getTransactionDataSource.setSuccess()
        );
      }),
      getTransactionDataSource.trackRequestStatus()
    );
  };

  deleteTransactionById = (transaction: TransactionFull) => {
    return from(APIAxios({ ...APIRoutes.DeleteTransactionById(transaction.id) })).pipe(
      catchError((err) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      tap(() => {
        this.store.update(deleteEntities(transaction.id));
      })
    );
  };

  getFullTransaction = (id: string): Observable<TransactionFull> => {
    return from(APIAxios({ ...APIRoutes.GETTransactionById(id) })).pipe(
      catchError((err) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<TransactionFull>) => {
        return response.data;
      }),
      getTransactionDataSource.trackRequestStatus()
    );
  };

  removeTransactionById = (id: string): void => {
    this.store.update(
      (state) => ({
        ...state,
        transaction: undefined,
      }),
      getTransactionDataSource.setSuccess()
    );
  };

  getTransactionTotal = (filters: TransactionFilter): Observable<TransactionTotal> => {
    return from(APIAxios({ ...APIRoutes.GETTrabsactionTotals(), params: { search: filters.search } })).pipe(
      catchError((err) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<TransactionTotal>) => {
        return response.data;
      }),
      tap((totals) => {
        this.store.update(
          (state) => ({
            ...state,
            totals,
          }),
          GETTransactionTotalDataSource.setSuccess()
        );
      }),
      GETTransactionTotalDataSource.trackRequestStatus()
    );
  };

  uploadInvoice = (transactionId: string, file: File): Observable<UploadFileResponse> => {
    const formData = new FormData();
    formData.append("attachment", file);

    return from(APIAxios({ ...APIRoutes.POSTInvoice(transactionId), data: formData })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<UploadFileResponse>) => {
        return response.data;
      })
    );
  };

  completeTransaction = (transaction: Partial<TransactionFull>): Observable<TransactionFull> => {
    const { updatedAt, updatedBy, ...formattedData } = transaction;
    return from(
      APIAxios({
        ...APIRoutes.POSTTransaction(transaction.id ?? ""),
        data: {
          ...formattedData,
          serviceCategory: transaction.serviceCategory?.id,
          user: transaction.user?.id,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        switch ((err.response as any).data?.message) {
          case "Incorrect Taxes":
            throw new SnackError("Montant de la taxe incorrect", "error", true);
          default:
            throw new SnackError((err.response as any)?.data?.message, "error");
        }
      }),
      map((response: AxiosResponse<TransactionFull>) => {
        return response.data;
      }),
      tap((newTransaction) => {
        this.getTransactions(this.store.state.filters);
        this.store.update(
          updateEntities(newTransaction.id, formatTransactionFullToTransactionLight(transaction, newTransaction))
        );
        this.store.update(
          (state) => ({
            ...state,
            newTransaction,
          }),
          getTransactionDataSource.setSuccess()
        );
      })
    );
  };

  updateTransactionIsUpdatable = (transaction: Partial<TransactionFull>): Observable<TransactionFull> => {
    return from(APIAxios({ ...APIRoutes.PATCHTransactionIsUpdatable(transaction.id ?? "") })).pipe(
      catchError((err) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<TransactionFull>) => {
        return response.data;
      }),
      tap((transaction) => {
        this.store.update(
          (state) => ({
            ...state,
            transaction,
          }),
          getTransactionDataSource.setSuccess()
        );
      }),
      getTransactionDataSource.trackRequestStatus()
    );
  };

  closePeriod = (period: Partial<TransactionClosePeriod>): Observable<AxiosResponse> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTClosePeriod(),
        params: {
          ...period,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        switch ((err.response as any).data?.status) {
          default:
            throw new SnackError((err.response as any)?.data?.message, "error");
        }
      }),
      map((response: AxiosResponse<AxiosResponse>) => {
        return response.data;
      })
    );
  };

  getCompanyExportOrder = (): Observable<ExportTransaction> => {
    return from(APIAxios(APIRoutes.GETCompanyExportTransaction())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<ExportTransaction>) => {
        return response.data;
      }),
      tap((ExportTransaction) => {
        this.setExportTransaction(ExportTransaction);
      })
    );
  };

  postExportTransaction = (exportTransaction: any, filters: Partial<ExportTransactionFilters>): Observable<void> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTExportTransaction(),
        data: {
          ...exportTransaction,
        },
        params: {
          ...filters,
        },
        //responseType: 'blob'
      })
    ).pipe(
      catchError((err) => {
        //setLoading(false);
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap((resp) => {
        const fileName = `export_transaction_${new Date().toISOString()}`;
        return downloadCSVObservable(resp.data, fileName);
        //return this.getCompanyExportOrder();
      })
    );
  };

  getMissingTransactions = (dateStart: Date, dateEnd: Date) => {
    return from(APIAxios(APIRoutes.GetMissingTransactions(dateStart, dateEnd))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse) => {
        return response.data;
      })
    );
  };
}

export const transactionServices = new TransactionServices();
