import React from "react";
import { ArrowForward } from "@mui/icons-material";
import { Chip, Grid, IconButton, Stack, Typography, alpha } from "@mui/material";
import {
  SwanStatusInfo,
  SwanTransactionSide,
  TransactionLight,
  TransactionStatus,
} from "../../.../../store/transaction";
import dayjs from "dayjs";
import { useResponsive } from "../../utils/useResponsive";
import { useTranslation } from "react-i18next";
import { Colors } from "src/constants/colors.constants";

interface PaymentCardComponentProps {
  transactionLight: TransactionLight;
  setSelectedTransaction: (x: TransactionLight) => void;
  setModalOpen: (x: boolean) => void;
}

const PaymentCardComponent = (props: PaymentCardComponentProps) => {
  const { transactionLight, setSelectedTransaction, setModalOpen } = props;
  const isMobile = useResponsive();
  const { t } = useTranslation();

  const isPending = transactionLight.swanStatusInfo === SwanStatusInfo.PENDING;
  const status = isPending ? TransactionStatus.PENDING : transactionLight.status;
  const statusLabel = TransactionStatus.label(status);
  const statusColor = TransactionStatus.color(status);
  const backgroundColor = alpha(statusColor, 0.2);

  return (
    <Stack
      key={transactionLight.id}
      direction={isMobile ? "column" : "row"}
      alignItems={isMobile ? "flex-start" : "center"}
      p={0.5}
      spacing={0.1}
    >
      <Grid container direction="row" alignItems="center" justifyContent={isMobile ? "flex-start" : "space-between"}>
        <Grid item xs={isMobile ? 12 : 9}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography fontSize={16} fontWeight={800}>
                {transactionLight.merchant.name}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography fontSize={16} fontWeight={800} pr={1}>
                {transactionLight?.serviceCategory?.name}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography fontSize={18} fontWeight={600}>
                {transactionLight.city}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography fontSize={18} fontWeight={600}>
                {transactionLight.country}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography fontSize={18} fontWeight={800}>
                {`${transactionLight.employee.firstName} ${transactionLight.employee.lastName}${
                  transactionLight.card ? " - " + transactionLight.card.name : ""
                }`}
              </Typography>
            </Grid>
            <Grid item xs>
              {transactionLight.dateOfService ? (
                <Typography fontSize={18} fontWeight={600}>
                  {Date.parse(transactionLight.dateOfService) &&
                    dayjs(transactionLight.dateOfService).format("dddd DD MMMM")}
                </Typography>
              ) : (
                transactionLight.date && (
                  <Typography fontSize={16} fontWeight={600}>
                    {Date.parse(transactionLight.date) && dayjs(transactionLight.date).format("dddd DD MMMM")}
                  </Typography>
                )
              )}
            </Grid>
            <Grid item xs>
              <Typography fontSize={18} fontWeight={600}>
                {`${transactionLight.swanTransactionSide === SwanTransactionSide.CREDIT ? "+" : "-"}`}
                {transactionLight.amount}€
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography fontSize={18} fontWeight={600}>
                {transactionLight.taxes?.length === 0
                  ? t("zero")
                  : transactionLight?.taxes.map((e) => e.percent + "%").join(", ")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={isMobile ? 12 : 1}>
          <Grid item xs>
            <Typography fontSize={18} fontWeight={600} color={transactionLight.noProof ? Colors.error : Colors.success}>
              {transactionLight.noProof && t("noJustificative")}
            </Typography>
          </Grid>
          {!transactionLight.noProof && (
            <Grid item xs>
              <Typography
                fontSize={18}
                fontWeight={600}
                color={transactionLight.isAmountMatch ? Colors.success : Colors.error}
              >
                {transactionLight.isAmountMatch === false && t("ocrNon")}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={isMobile ? 12 : 2}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <Chip
              label={statusLabel}
              style={{
                fontSize: "18px",
                fontWeight: 600,
                backgroundColor: backgroundColor,
                color: statusColor,
              }}
            />
            <IconButton
              onClick={() => {
                setSelectedTransaction(transactionLight);
                setModalOpen(true);
              }}
            >
              <ArrowForward />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default PaymentCardComponent;
